<template>
  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4>
                    المواقف اليومية الطارئة <button v-b-modal.add class="btn btn-sm btn-primary" >
                        <i class="fa fa-plus"></i>
                        اضافة موقف
                    </button>
                </h4>
                <div class="gs">
                    <button class="btn btn-sm btn-danger" v-b-modal.filter style="border-radius: 0px 5px 5px 0px">
                        <i class="fa fa-filter"></i>
                        فلترة النتائج
                    </button>
                    <button class="btn btn-sm btn-warning" style="border-radius: 5px 0px 0px 5px" @click="print(items)">
                        <i class="fa fa-print"></i>
                        طباعة الصفحة
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>
                                    الطالب
                                </th>
                                <th>
                                    الصف
                                </th>
                                <th>
                                    التاريخ
                                </th>
                                <th>
                                    الموقف
                                </th>
                                <th>
                                    الاجراء
                                </th>
                                <th>
                                    خيارات
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item._id">
                                <td>
                                    {{ getStudent(item.number).name }}
                                </td>
                                <td>
                                    {{ getStudent(item.number).classname }}
                                </td>
                                <td>
                                    {{ item.date }}
                                </td>
                                <td>
                                    {{ item.content }}
                                </td>
                                <td>
                                    {{ item.action }}
                                </td>
                                <td class="hidemonprint">
                                    <b-dropdown dropleft style="width: 100%"
                                        id="dropdown-1"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        text="خيارات"
                                        variant="secondary"
                                        size="sm"
                                    >
                                        <b-dropdown-item @click="print([item])">
                                            <i class="fa fa-print"></i>
                                            طباعة
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="deleteItem(item._id)">
                                            <i class="fa fa-trash"></i>
                                            حذف الموقف
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="add" hide-footer size="sm">
        اختر الطالب:
        <v-select :options="students" dir="rtl" v-model="selectedStudent" placeholer="اختر الطالب..." label="name">
        </v-select>
        <br>
        <div class="form-group">
          <label for="">الموقف الطارئ</label>
          <textarea type="text"
            class="form-control" placeholder="اكتب هنا..." v-model="content"></textarea>
        </div>
        <div class="form-group">
          <label for="">الاجراء المتخذ</label>
          <select class="form-control" v-model="action">
            <option value="">-- اختر --</option>
            <option v-for="ac in actions" :key="ac">{{ ac }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">التاريخ</label>
          <input type="date"
            class="form-control" v-model="date">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="addBreak()">
                اضافة الموقف
                <i class="fa fa-arrow-left"></i>
            </button>
        </div>
    </b-modal>
    <b-modal id="filter" hide-footer size="sm">
        اختر الطالب: (اتركه فارغاً لتحديد الكل)
        <v-select :options="students" dir="rtl" v-model="student_number" placeholer="اختر الطالب..." label="name">
        </v-select>
        <br>
        <div class="form-group">
          <label for="">التاريخ من</label>
          <input type="date"
            class="form-control" v-model="from">
        </div>
        <div class="form-group">
          <label for="">التاريخ الى</label>
          <input type="date"
            class="form-control" v-model="to">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="closeFilter();getItems()">
                <i class="fa fa-search"></i>
                بحث
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        vSelect,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            items: [],
            user: JSON.parse(localStorage.getItem('user')),
            student_number: {},
            from: "",
            to: "",
            students: [],
            selectedStudent:  { name: "", number: "" },
            content: "",
            action: "",
            numbers: {},
            actions: [
                "تبصير الطالب بالمشكلة",
                "جلسة ارشادية مع الطالب",
                "اشراك الطالب في النشاط",
                "استدعاء ولي الامر",
                "احالة الطالب للوكيل",
                "مساعدة الطالب على التكيف",
                "دراسة حالة الطالب",
                "احالة الطالب لوحدة الخدمات الارشادية",
                "مقابلة معلم المادة للتباحث معه حول المشكلة",
                "استخدام الضبط الذاتي",
                "تنظيم وقت الطالب",
                "استخدام فنيات تعزيز السلوك"
            ],
            date: new Date().toISOString().split("T")[0]
        }
    },
    created(){
        if(!checkPer("students|breaks-visits")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getStudents();
    },
    methods: {
        closeFilter(){
            $("#filter___BV_modal_header_ > button").click();
        },
        getItems(){
            var g = this;
            $.post(api + '/user/students/breaks/list', {
                jwt: this.user.jwt,
                number: this.student_number.number,
                from: this.from,
                to: this.to
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.items = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.user.jwt,
                q: this.q,
                page: 1,
                perpage: 10000
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response
                    g.students.forEach(function(a){
                        g.numbers[a.number] = a;
                    })
                    g.getItems()
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        getStudent(number){
            return this.numbers[number] ?? {};
        },
        addBreak(){
            var g = this;
            $.post(api + '/user/students/breaks/add', {
                jwt: g.user.jwt,
                number: g.selectedStudent.number,
                date: g.date,
                content: g.content,
                action: g.action
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    $("#add___BV_modal_header_ > button").click();
                    g.getItems()
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        deleteItem(id){
            var g = this;
            if(confirm("متأكد من حذف الموقف؟")){
                var g = this;
                $.post(api + '/user/students/breaks/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        g.getItems()
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            }
        },
        print(arr){
            var tbody = '', index = 0, g = this;
            arr.forEach(function(a){
                index++
                tbody = tbody + `
                <tr>
                    <td>${index}</td>
                    <td>${g.getStudent(a.number).name}</td>
                    <td>${g.getStudent(a.number).classname}</td>
                    <td>${a.date}</td>
                    <td>${a.content}</td>
                    <td>${a.action}</td>
                </tr>
                `
            })
            var win = window.open("", "")
            win.document.write(`
            <html dir='rtl'>
                <head>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.2/css/bootstrap.min.css"/>
                </head>
                <body>
                    <div class='container-fluid'>
                        <br>
                        <div class='row'>
                            <div class='col-4 text-center'>
                            <h5>
                                المملكة العربية السعودية
                                <br><br>وزارة التعليم
                            </h5>
                            </div>
                            <div class='col-4 text-center'>
                                <img style='width: 200px' src='https://static.arrajol.com/users/user223141/%D8%B4%D8%B9%D8%A7%D8%B1-%D9%88%D8%B2%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%AA%D8%B9%D9%84%D9%8A%D9%85-%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF-%D8%B4%D9%81%D8%A7%D9%81.png'>
                                <h3>بسم الله الرحمن الرحيم</h3>
                            </div>
                            <div class='col-4 text-center'>
                            <h5>
                                ${g.user.name}
                                <br>
                                مكتب الموجه الطلابي
                            </h5>
                            </div>
                            <div class='col-12'>
                                <center><h2 class='text-danger'>سجل المواقف اليومية الطارئة</h2></center>
                                <br>
                                <table class='table table-bordered table-striped'>
                                    <thead style='background: #B0D9B1'>
                                        <th>م</th>
                                        <th>اسم الطالب</th>
                                        <th>الصف</th>
                                        <th>التاريخ</th>
                                        <th>الموقف الطارئ</th>
                                        <th> الاجراء المتخذ</th>
                                    </thead>
                                    <tbody>
                                    ${tbody}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
            `)
            win.document.close()
            win.print()
        }
    }
}
</script>

<style>
@import url(https://cdnjs.cloudflare.com/ajax/libs/vue-select/3.10.0/vue-select.css);
#vs3__listbox{
    max-height: 150px !important;
    overflow: hidden !important;
}
</style>